<template>
    <div class="container">
        <!-- <img src="@/assets/valetParkingCard.png" class="rounded float-start" width="500px"> -->
        <div class="center">



            <div class="card" style="max-width: 30rem;">
                <img src="@/assets/valetParkingCard.png" class="card-img" alt="..." width="500px">
                <div class="card-img-overlay">

                    <template v-if="issent">
                        <h5 class="card-title text-center"
                            style="margin-top:170px;padding-left: 35px; padding-right: 35px;">
                            شكراً لمشاركتنا رأيك.
                            <br>
                            <span dir="ltr">
                                Thank you for sharing your opinion.
                            </span>
                        </h5>

                    </template>
                    <template v-else>


                        <h5 class="card-title text-center"
                            style="margin-top:170px;padding-left: 35px; padding-right: 35px;">
                            كيف كانت خدمتنا؟
                            <br>
                            <span dir="ltr">
                                How was our service?
                            </span>
                        </h5>


                        <div class=" d-flex justify-content-center mt-5">
                            <div class=" text-center mb-5">
                                <div class="rating">
                                    <template v-for="(item) in rating">
                                        <input type="radio" name="rating" :value="item" :id="item"
                                            v-model="rating_selected">
                                        <label :for="item">☆</label>
                                    </template>


                                </div>
                                <div class="buttons  mt-0">
                                    <button class="btn btn-primary px-4 py-1 rating-submit " @click="requestRatingService"
                                        :disabled="!rating_selected">
                                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                        إرسال
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>

                </div>

            </div>
        </div>
    </div>
</template>

<script>

import requestService from "@/services/requestService";

export default {
    name: 'Evaluation',
    data() {
        return {
            objectKey: null,
            uuid: null,
            request: null,
            rating: [5, 4, 3, 2, 1],
            rating_selected: null,
            issent: false,
            loading : false,
        };
    },
    methods: {

        requestRatingService: function () {
            this.loading = true;
            var data = { objectKey: this.objectKey, ratingValue: this.rating_selected };
            console.log(data);
            var result = requestService.requestRatingService(this.objectKey, data)
                .then(response => {


                })
                .catch(e => {
                    console.log(e);
                })
                .finally(e => {
                    this.issent = true;
                    this.loading = false;
                });

        },

        retrieveData() {
            requestService.getByUUID(this.uuid)
                .then(response => {
                    this.request = response.data;
                    this.objectKey = this.request.objectKey;
                })
                .catch(e => {
                    console.log(e);
                });

        },
    },
    mounted() {
        this.uuid = this.$route.params.key;
        this.retrieveData();
    }
};
</script>

<style scoped lang="scss">
.rate {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px
}

.rating {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center
}

.rating>input {
    display: none
}

.rating>label {
    position: relative;
    width: 1em;
    font-size: 60px;
    font-weight: 300;
    color: #FFD600;
    cursor: pointer
}

.rating>label::before {
    content: "\2605";
    position: absolute;
    opacity: 0
}

.rating>label:hover:before,
.rating>label:hover~label:before {
    opacity: 1 !important
}

.rating>input:checked~label:before {
    opacity: 1
}

.rating:hover>input:checked~label:before {
    opacity: 0.4
}

.buttons {
    top: 36px;
    position: relative
}

.rating-submit {
    border-radius: 8px;
    color: #fff;
    height: auto;
    background-color: rgb(17, 16, 50)
}

.rating-submit:hover {
    color: #fff;
    background-color: rgb(9, 8, 25)
}
</style>
