import http from "./http-common";

import axios from 'axios';

let headers = {
    "Content-type": "application/json",
    "Accept": "text/plain",
};

const api = axios.create({
    baseURL: process.env.VUE_APP_BE_BASE_URL + '/api',
    headers: headers
});


class manualCardService {
    getAll() {
        return http.get("/manualCards");
    }

    getByStatusId(statusId) {
        return http.get(`/manualCards/statusId/${statusId}`);
    }
    getByObjectKey(objectKey) {
        return api.get(`/manualCards/ObjectKey/${objectKey}`);
    }

    getByUUID(uuid) {
        return api.get(`/manualCards/uuid/${uuid}`);
    }


    manualCardToReceive(objectKey, data) {
        return api.post(`/manualCards/${objectKey}/manualCardToReceive`, data);
    }

    manualCardToReceiveClosed(objectKey) {
        return http.post(`/manualCards/${objectKey}/ReceiveClosed`,{objectKey: objectKey});
    }


    create(data) {
        return http.post("/manualCards", data);
    }

    update(id, data) {
        return http.put(`/manualCards/${id}`, data);
    }

    setManualCardToInParking(data) {
        return http.put(`/manualCards/ManualCardToInParking`, data);
    }
}

export default new manualCardService();