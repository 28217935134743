import http from "./http-common";

import axios from 'axios';

let headers = {
    "Content-type": "application/json",
    "Accept": "text/plain",
};

const api = axios.create({
    baseURL: process.env.VUE_APP_BE_BASE_URL + '/api',
    headers: headers
});


class requestService {
    getAll() {
        return http.get("/requests");
    }

    getMyRequestsByProjectPeriodId(projectPeriodId) {
        return http.get(`/requests/projectPeriods/{projectPeriodId}/my`);
    }

    getMyTodayRequestsIsRequestToReceive() {
        return http.get("/requests/MyToday/RequestsIsRequestToReceive");
    }

    getMyRequestsIsRequestToReceive() {
        return http.get("/requests/MyRequestsIsRequestToReceive");
    }

    getRequestsIsRequestToReceive() {
        return http.get("/requests/RequestsIsRequestToReceive");
    }

    get(id) {
        return http.get(`/requests/${id}`);
    }

    getByObjectKey(objectKey) {
        return api.get(`/requests/ObjectKey/${objectKey}`);
    }

    getByUUID(uuid) {
        return api.get(`/requests/uuid/${uuid}`);
    }


    requestToReceive(objectKey, data) {
        return api.post(`/requests/${objectKey}/RequestToReceive`, data);
    }

    requestToReceiveClosed(objectKey, data) {
        return http.post(`/requests/${objectKey}/RequestToReceiveClosed`, data);
    }

    requestToReceiveUnderProcessing(objectKey, data) {
        return http.post(`/requests/${objectKey}/RequestToReceiveUnderProcessing`, data);
    }

    requestRatingService(objectKey, data) {
        return api.post(`/requests/${objectKey}/Rating`, data);
    }

    create(data) {
        return http.post("/requests", data);
    }

    update(id, data) {
        return http.put(`/requests/${id}`, data);
    }

    delete(id) {
        return http.delete(`/requests/${id}`);
    }
}

export default new requestService();