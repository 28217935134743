<template>
    <router-view />
</template>

<script>
//import requestHub from "@/hubs/requestHub";
export default {
    components: {
    },
    mounted() {

        // requestHub.client.on('gameStarted', data => {
        //      console.log(data);
        // });
        // requestHub.start();
    },
    unmounted() {
        //requestHub.discos
    }
};
</script>