<template>

    <Evaluation />

</template>

<script>
// @ is an alias to /src
import Evaluation from '@/components/Requests/Evaluation.vue'

export default {
  name: 'EvaluationView',
  components: {
    Evaluation
  }
}
</script>
