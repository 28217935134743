
import sound from '@/assets/beep01.mp3';

const audio = new Audio(sound);
audio.muted = true;
//audio.volume = 0;

window.addEventListener("mouseover", (e) => {
    //console.log(audio.muted)
    if(audio.muted == true)
    {
        // audio.volume = 0;
        // audio.play();
        audio.muted = false;
        //audio.volume = 1;
    }
    //console.log(audio.muted)
  });

class soundPlayService {
    playNewNotification() {
        try {
            audio.play();    
        } catch (error) {
            
        }
        
    }
}

export default new soundPlayService();
