<template>
    <div class="container">
        <!-- <img src="@/assets/valetParkingCard.png" class="rounded float-start" width="500px"> -->
        <div class="center">



            <div class="card" style="max-width: 30rem;">
                <img src="@/assets/valetParkingCard.png" class="card-img" alt="..." width="500px">
                <div class="card-img-overlay">



                    <h5 class="card-title text-center" style="margin-top:170px;padding-left: 35px; padding-right: 35px;">
                        خدمة إيقاف السيارة
                        <br>
                        valet parking service
                    </h5>


                    <div class="row" style="padding-left: 35px; padding-right: 35px;">

                        <template v-if="manualCard != null">
                            <div class="text-center" style="color: red; font-weight: bold;">
                                {{ manualCard.idStr }}
                            </div>

                            <table class="table">
                               
                                
                                <tr>
                                    <th>رقم الموقف</th>
                                    <td>{{ manualCard.parkingLotNo }}</td>
                                </tr>
                                <tr>
                                    <th>وقت الدخول</th>
                                    <td>{{  }}</td>
                                </tr>
                            </table>

                            <div>

                                <template v-if="manualCard.manualCardStatusId == 1">

                                </template>
                                <div class="d-flex  justify-content-center" v-else-if="manualCard.manualCardStatusId == 2">
                                    <button @click="manualCardToReceive" class="btn btn-primary">طلب استلام السيارة</button>
                                </div>
                                <template v-else>

                                    <div class="text-center">

                                        <div class="justify-content-center">
                                            تم طلب الاستلام 
                                        </div>

                                        <div>
                                            {{  }}
                                        </div>
                                       
                                       
                                    </div>

                                </template>
                            </div>
                        </template>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import manualCardService from "@/services/manualCardService";


export default {
    name: 'ecard',
    data() {
        return {
            objectKey: null,
            uuid: null,
            manualCard: null
        };
    },
    methods: {
        manualCardToReceive: function () {
            var result = manualCardService.manualCardToReceive(this.objectKey, { objectKey: this.objectKey })
                .then(response => {
                    this.retrieveData();
                })
                .catch(e => {
                    console.log(e);
                });

        },
        retrieveData() {
            manualCardService.getByUUID(this.uuid)
                .then(response => {
                    this.manualCard = response.data;
                    this.objectKey = this.manualCard.objectKey;
                    console.log(response.data);

                })
                .catch(e => {
                    console.log(e);
                });

        },
    },
    mounted() {
        this.uuid = this.$route.params.key;
        //this.objectKey = this.$route.params.key;
        this.retrieveData();


    }
};
</script>

<style scoped lang="scss"></style>
