import soundPlayService from "@/services/soundPlayService";

const user = JSON.parse(localStorage.getItem('user'));
const initialState = {
    manualCardToReceived : null,
}

export const manualCardHub = {
    namespaced: true,
    state: initialState,
    actions: {
    },
    mutations: {
        manualCardToReceived(state, data) {
            state.manualCardToReceived = data;

            console.log('state.manualCardToReceived');
            soundPlayService.playNewNotification();
        }
    },
    getters: {
        manualCardToReceived() {
           return state.manualCardToReceived;
        },
    }
};