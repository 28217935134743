<template>

    <RequestToReceive />

</template>

<script>
// @ is an alias to /src
import RequestToReceive from '@/components/Requests/RequestToReceive.vue'

export default {
  name: 'RequestToReceiveView',
  components: {
    RequestToReceive
  }
}
</script>
