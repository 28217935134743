<template>

    <ecard />

</template>

<script>
// @ is an alias to /src
import ecard from '@/components/ManualCards/ecard.vue'

export default {
  name: 'ManualCardECardView',
  components: {
    ecard
  }
}
</script>
